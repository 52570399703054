<template>
  <div class="section-2 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row justify="space-between" class="evo-mt">
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <div class="evo-summary-items">
            <img loading="lazy" :src="item.icon" class="evo-img" />
            <div class="evo-title evo-text-50">{{ $t(item.title) }}</div>
            <div class="evo-desc evo-text-38 evo-opensans-regular">
              {{ $t(item.desc) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="evo-row evo-management-tools-wrapper">
        <v-col>
          <div class="evo-management-tools">
            <div class="evo-title evo-text-58 evo-mb-48">
              {{ $t('fraud_protect.s2_title1') }}
            </div>
            <div class="evo-desc evo-text-28 evo-mb-100 evo-opensans-regular">
              {{ $t('fraud_protect.s2_1_desc') }}
            </div>
            <v-row align="center" justify="center">
              <v-col cols="12" v-for="(item, index) in managementTools" :key="index">
                <div class="evo-management-tools-item">
                  <img loading="lazy" :src="item.icon" class="evo-img" />
                  <div class="evo-title text-center evo-text-43 evo-mb-48">{{ $t(item.title) }}</div>
                  <div class="evo-desc evo-text-27 text-center evo-mb-48 evo-opensans-regular mb-2">
                    {{ $t(item.desc) }}
                  </div>
                  <div class="evo-desc evo-text-27 evo-mb-120 evo-opensans-regular">
                    {{ $t(item.desc1) }}
                  </div>

                  <img v-if="index < 2" loading="lazy" class="evo-arrow-right" src="@/assets/fraud-protect/arrow_right.svg" />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <EvoEllipse class="evo-s2-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />

      <EvoRect color="gradientBlue" class="evo-s2-rect" />
    </div>
  </div>
</template>
<script>
import { fraudProtectSection2Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: 'EvoMFraudProtectSectionTwo',
  mixins: [fraudProtectSection2Mixin]
};
</script>
<style lang="scss" scoped>
.section-2 {
  position: relative;
  background: #fafafa;

  .evo-summary-items {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 42px 168px 0px rgba(0, 0, 0, 0.15);
    border: 2px solid #d8d8d8;
    border-radius: 36px;
    width: 640px;
    margin: 0 auto;
    padding: 16px;
    min-height: 720px;
    height: auto;
    padding: 48px;

    .evo-img {
      width: 200px;
      height: auto;
      margin-bottom: 32px;
    }

    .evo-title {
      color: #222a41;
      margin-bottom: 48px;
    }

    .evo-desc {
      color: #6d7783;
    }

    .evo-title,
    .evo-desc {
      text-align: center;
    }
  }

  .evo-mt {
    margin-top: -48vw;
  }

  .evo-management-tools-wrapper {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    .evo-management-tools {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 36px 145px 0px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      padding: 48px;
      width: 640px;
      margin: 0 auto;

      & > .evo-title,
      & > .evo-desc {
        text-align: center;
      }

      & > .evo-title {
        color: #222a41;
        margin-top: 8px;
      }

      & > .evo-desc {
        color: #6d7783;
      }

      .evo-management-tools-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .evo-img {
          width: 172px;
          height: auto;
          margin: 0 auto;
          margin-bottom: 32px;
          text-align: center;
        }

        .evo-title {
          color: #222a41;
          text-align: left;
        }

        .evo-desc {
          color: #6d7783;
        }

        .evo-arrow-right {
          position: absolute;
          bottom: 0;
          width: 54px;
          height: auto;
          transform: rotate(90deg);
        }
      }
    }
  }

  .evo-s2-ellipse {
    position: absolute;
    left: 15px;
    top: 260vw;
    z-index: 0;
  }

  .evo-s2-rect {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 39px;
  }
}
</style>
