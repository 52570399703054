<template>
  <div class="section-3 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-title text-center evo-text-58 evo-mb-8">{{ $t('fraud_protect.s3_title') }}</div>
      <div class="evo-desc text-center evo-text-30 evo-mb-8 evo-opensans-regular">
        {{ $t('fraud_protect.s3_desc1') }}
      </div>
      <div class="evo-desc text-center evo-text-30 evo-mb-100 evo-opensans-regular">
        {{ $t('fraud_protect.s3_desc2') }}
      </div>
      <v-row class="evo-items-wrapper" justify="space-between">
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <div class="evo-item">
            <img loading="lazy" :src="item.icon" class="evo-img" />
            <div class="evo-desc evo-text-38 evo-opensans-regular text-center">
              {{ $t(item.desc) }}
            </div>

            <img
              loading="lazy"
              v-if="item.iconR"
              :src="item.iconR"
              :class="{
                'evo-img-top': index % 2 === 0,
                'evo-img-bottom': index % 2 === 1
              }"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { fraudProtectSection3Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: 'EvoMFraudProtectSectionThree',
  mixins: [fraudProtectSection3Mixin]
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;
  background: #fafafa;

  & > .evo-title,
  & > .evo-desc {
    text-align: center;
  }

  & > .evo-title {
    color: #222a41;
  }

  & > .evo-desc {
    color: #6d7783;
  }

  .evo-items-wrapper {
    .evo-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 134px;

      .evo-img {
        width: 136px;
        height: auto;
      }

      .evo-img-top {
        position: absolute;
        top: 270px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        width: 162px;
      }

      .evo-img-bottom {
        position: absolute;
        top: 270px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        width: 162px;
      }

      .evo-desc {
        color: #151517;
      }
    }
  }
}
</style>
