<template>
  <div class="section-2 evo-ph-206 evo-pv-60">
    <v-row justify="space-between" class="evo-row">
      <v-col v-for="(item, index) in items" :key="index">
        <div
          class="evo-summary-items"
          :class="{ 'evo-mt': index === 1 }"
          data-aos="fade-up"
        >
          <img loading="lazy" 
            :src="item.icon"
            class="evo-img"
            :class="{ middle: index === 1 }"
          />
          <div class="evo-title evo-text-16">{{ $t(item.title) }}</div>
          <div class="evo-desc evo-text-13 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="evo-row evo-management-tools-wrapper">
      <v-col data-aos="fade-up" data-aos-delay="100">
        <div class="evo-management-tools">
          <div class="evo-title evo-text-29">
            {{ $t("fraud_protect.s2_title1") }}
          </div>
          <div class="evo-desc evo-text-13 evo-opensans-regular">
            {{ $t("fraud_protect.s2_1_desc") }}
          </div>
          <v-row class="evo-row evo-row-padding">
            <v-col v-for="(item, index) in managementTools" :key="index">
              <div class="evo-management-tools-item">
                <img loading="lazy"  v-if="index === 2" :src="item.icon" class="evo-img" />
                <div class="evo-img-wrapper" v-if="index < 2">
                  <img loading="lazy"  :src="item.icon" class="evo-img" />
                  <img loading="lazy" 
                    class="evo-arrow-right"
                    src="@/assets/fraud-protect/arrow_right.svg"
                  />
                </div>
                <div class="evo-title evo-text-16">{{ $t(item.title) }}</div>
                <div class="evo-desc evo-text-10 evo-opensans-regular mb-2">
                  {{ $t(item.desc) }}
                </div>
                <div class="evo-desc evo-text-10 evo-opensans-regular">
                  {{ $t(item.desc1) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <EvoEllipse
      class="evo-s2-ellipse"
      background="lightYellow"
      transform="rotate(0deg)"
      filter="blur(25px)"
      width="150px"
      height="150px"
    />

    <EvoRect color="gradientBlue" class="evo-s2-rect" />
  </div>
</template>
<script>
import { fraudProtectSection2Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: "EvoFraudProtectSectionTwo",
  mixins: [fraudProtectSection2Mixin]
};
</script>
<style lang="scss" scoped>
.section-2 {
  position: relative;
  background: #fafafa;

  .evo-row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .evo-summary-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 16px;
    min-height: 230px;
    height: auto;
    margin-top: -330px;

    .evo-img {
      width: 40px;
      margin-bottom: 8px;
      &.middle {
        margin-bottom: 8px;
      }
    }

    .evo-title {
      margin-bottom: 18px;
      color: #222a41;
    }

    .evo-desc {
      color: #6d7783;
      line-height: 22px;
    }

    .evo-title,
    .evo-desc {
      text-align: center;
    }
  }

  .evo-mt {
    margin-top: -370px;
  }

  .evo-management-tools-wrapper {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    .evo-management-tools {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 17px 68px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      padding: 16px;

      .evo-row-padding {
        padding: 24px;
      }

      & > .evo-title,
      & > .evo-desc {
        text-align: center;
      }

      & > .evo-title {
        color: #222a41;
        margin-top: 8px;
        margin-bottom: 16px;
      }

      & > .evo-desc {
        color: #6d7783;
        margin: 0 42px;
        margin-bottom: 24px;
      }

      .evo-management-tools-item {
        display: flex;
        flex-direction: column;

        .evo-img {
          width: 54px;
          margin-bottom: 12px;
        }

        .evo-img-wrapper {
          display: flex;
          justify-content: space-between;

          .evo-arrow-right {
            margin-right: 32px;
            width: 24px;
            height: auto;
          }
        }

        .evo-title {
          color: #222a41;
          text-align: left;
          margin-bottom: 16px;
        }

        .evo-desc {
          color: #6d7783;
          text-align: left;
        }
      }
    }
  }

  .evo-s2-ellipse {
    position: absolute;
    left: 150px;
    top: 35px;
    z-index: 0;
  }

  .evo-s2-rect {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 39px;
  }
}
</style>
