import EvoEllipse from "@/components/Ellipse.vue";
import EvoRect from "@/components/Rect.vue";

export const fraudProtectSection2Mixin = {
    components: {
        EvoEllipse,
        EvoRect,
    },
    data: () => ({
        items: [
            {
                key: 1,
                icon: require("@/assets/fraud-protect/s2_1.png"),
                title: "fraud_protect.s2_title1",
                desc: "fraud_protect.s2_desc1",
            },
            {
                key: 2,
                icon: require("@/assets/fraud-protect/s2_2.png"),
                title: "fraud_protect.s2_title2",
                desc: "fraud_protect.s2_desc2",
            },
            {
                key: 3,
                icon: require("@/assets/fraud-protect/s2_3.png"),
                title: "fraud_protect.s2_title3",
                desc: "fraud_protect.s2_desc3",
            },
        ],
        managementTools: [
            {
                key: 1,
                icon: require("@/assets/fraud-protect/s2_1_1.png"),
                title: "fraud_protect.s2_1_title1",
                desc: "fraud_protect.s2_1_desc1_1",
                desc1: "fraud_protect.s2_1_desc1_2",
            },
            {
                key: 2,
                icon: require("@/assets/fraud-protect/s2_1_2.png"),
                title: "fraud_protect.s2_1_title2",
                desc: "fraud_protect.s2_1_desc2_1",
                desc1: "fraud_protect.s2_1_desc2_2",
            },
            {
                key: 3,
                icon: require("@/assets/fraud-protect/s2_1_3.png"),
                title: "fraud_protect.s2_1_title3",
                desc: "fraud_protect.s2_1_desc3_1",
                desc1: "fraud_protect.s2_1_desc3_2",
            },
        ],
    }),

}

export const fraudProtectSection3Mixin = {
    data: () => ({
        items: [
            {
                icon: require("@/assets/fraud-protect/s3_icon_1.png"),
                iconR: require("@/assets/fraud-protect/s3_icon_top.png"),
                desc: "fraud_protect.s3_1_desc1",
            },
            {
                icon: require("@/assets/fraud-protect/s3_icon_2.png"),
                iconR: require("@/assets/fraud-protect/s3_icon_bottom.png"),
                desc: "fraud_protect.s3_1_desc2",
            },
            {
                icon: require("@/assets/fraud-protect/s3_icon_3.png"),
                iconR: require("@/assets/fraud-protect/s3_icon_top.png"),
                desc: "fraud_protect.s3_1_desc3",
            },
            {
                icon: require("@/assets/fraud-protect/s3_icon_4.png"),
                iconR: require("@/assets/fraud-protect/s3_icon_bottom.png"),
                desc: "fraud_protect.s3_1_desc4",
            },
            {
                icon: require("@/assets/fraud-protect/s3_icon_5.png"),
                desc: "fraud_protect.s3_1_desc5",
            },
        ],
    }),
}

export const fraudProtectSection4Mixin = {
    components: {
        EvoEllipse
    },
    data() {
        return {
            solutions: [
                {
                    icon: require('@/assets/merchant-services/s4_1.png'),
                    title: 'home_merchant.s4_1_title',
                    desc: 'home_merchant.s4_1_desc',
                    isWhiteBg: true
                },
                {
                    icon: require('@/assets/merchant-services/s4_2.png'),
                    title: 'home_merchant.s4_2_title',
                    desc: 'home_merchant.s4_2_desc'
                },
                {
                    icon: require('@/assets/merchant-services/s4_3.png'),
                    title: 'home_merchant.s4_3_title',
                    desc: 'home_merchant.s4_3_desc'
                },
                {
                    icon: require('@/assets/merchant-services/s4_4.png'),
                    title: 'home_merchant.s4_4_title',
                    desc: 'home_merchant.s4_4_desc',
                    isWhiteBg: true
                }
            ]
        };
    }
}