<template>
  <div class="section-4 evo-ph-206 evo-pv-60">
    <div class="evo-text-34 evo-title" data-aos="fade-up">
      {{ $t('fraud_protect.s4_title') }}
    </div>
    <div class="evo-text-14 evo-opensans-regular evo-sub-title" data-aos="fade-up" data-aos-delay="100">
      {{ $t('fraud_protect.s4_sub_title') }}
    </div>

    <v-row class="evo-row" align="center" justify="space-between">
      <v-col class="pa-4" cols="12" md="6" v-for="(item, index) in solutions" :key="index">
        <div class="evo-solutions" :class="{ 'is-white-bg': item.isWhiteBg }" data-aos="fade-up">
          <img class="evo-item-img" loading="lazy" :src="item.icon" />
          <div class="evo-item-title evo-text-20">{{ $t(item.title) }}</div>
          <div class="evo-item-desc evo-text-12 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <EvoEllipse class="evo-s4-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="200px" height="200px" />
  </div>
</template>
<script>
import { fraudProtectSection4Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: 'EvoFraudProtectSectionFour',
  mixins: [fraudProtectSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: $header_bg_color;

  .evo-title {
    color: #222a41;
    line-height: 43px;
    text-align: center;
    margin-bottom: 8px;
    white-space: pre-line;
  }

  .evo-sub-title {
    color: #a0a3a8;
    line-height: 23px;
    margin-bottom: 48px;
    text-align: center;
  }

  .evo-solutions {
    background: #2161ff;
    box-shadow: 0px 11px 11px 0px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    padding: 16px;
    min-height: 264px;
    position: relative;
    z-index: 1;

    &.is-white-bg {
      background: #fff;

      .evo-item-title {
        color: $title_color !important;
      }

      .evo-item-desc {
        color: #a0a3a8 !important;
      }
    }
    .evo-item-img {
      width: 96px;
      height: 96px;
      margin-bottom: 4px;
    }

    .evo-item-title {
      color: #ffffff;
      margin-bottom: 12px;
    }

    .evo-item-desc {
      color: #ffffff;
    }
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 124px;
    top: 124px;
    z-index: 0;
  }
}
</style>
