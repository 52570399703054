<template>
  <div class="evo-fraud-protect-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMFraudProtectSectionOne />
      <EvoMFraudProtectSectionTwo />
      <EvoMFraudProtectSectionThree />
      <EvoMFraudProtectSectionFour />
    </template>
    <template v-else>
      <EvoFraudProtectSectionOne />
      <EvoFraudProtectSectionTwo />
      <EvoFraudProtectSectionThree />
      <EvoFraudProtectSectionFour />
    </template>
  </div>
</template>
<script>
import EvoFraudProtectSectionOne from '@/components/fraud_protect_sections/Section_1.vue';
import EvoFraudProtectSectionTwo from '@/components/fraud_protect_sections/Section_2.vue';
import EvoFraudProtectSectionThree from '@/components/fraud_protect_sections/Section_3.vue';
import EvoFraudProtectSectionFour from '@/components/fraud_protect_sections/Section_4.vue';

// mobile imports
import EvoMFraudProtectSectionOne from '@/mobile/components/fraud_protect_sections/MSection_1.vue';
import EvoMFraudProtectSectionTwo from '@/mobile/components/fraud_protect_sections/MSection_2.vue';
import EvoMFraudProtectSectionThree from '@/mobile/components/fraud_protect_sections/MSection_3.vue';
import EvoMFraudProtectSectionFour from '@/mobile/components/fraud_protect_sections/MSection_4.vue';
export default {
  name: 'EvoFraudProtect',
  components: {
    EvoFraudProtectSectionOne,
    EvoFraudProtectSectionTwo,
    EvoFraudProtectSectionThree,
    EvoFraudProtectSectionFour,

    EvoMFraudProtectSectionOne,
    EvoMFraudProtectSectionTwo,
    EvoMFraudProtectSectionThree,
    EvoMFraudProtectSectionFour
  }
};
</script>
<style lang="scss" scoped>
.evo-fraud-protect-wrapper {
  position: relative;
  background: $header_bg_color;
}
</style>
