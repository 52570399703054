<template>
  <div class="section-1 evo-ph-176 evo-pv-60">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      class="evo-text-51 evo-title"
    >
      {{ $t("fraud_protect.title") }}
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="1000"
      class="evo-text-15 evo-desc evo-opensans-regular"
    >
      {{ $t("fraud_protect.desc") }}
    </div>
    <div class="evo-get-started">
      <router-link to="contact-us" class="router-link">
        <v-btn
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
          rounded
          x-large
          color="default"
          class="text-none evo-text-9 evo-get-started-btn"
        >
          {{ $t("main_entry.get_started") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "EvoFraudProtectSectionOne",
};
</script>
<style lang="scss" scoped>
.section-1 {
  position: relative;
  background-image: url("~@/assets/main/main-bg.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100%;
  color: #ffffff;
  padding-bottom: 80vh;
  // background-color: $nav_color; //会导致页面背景高于页面图片

  .evo-title,
  .evo-desc {
    text-align: center;
  }

  .evo-title {
    margin: 24px 56px;
  }

  .evo-desc {
    margin: 0 132px;
    margin-bottom: 48px;
  }

  .evo-get-started {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .evo-get-started-btn {
      @include whiteblue_btn;
      min-width: 128px;
      color: #459eff;
    }
  }
}
</style>
