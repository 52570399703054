<template>
  <div class="section-3 evo-ph-206 evo-pv-60">
    <div class="evo-title evo-text-29" data-aos="fade-up">{{ $t("fraud_protect.s3_title") }}</div>
    <div class="evo-desc evo-text-15 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
      {{ $t("fraud_protect.s3_desc1") }}
    </div>
    <div class="evo-desc evo-text-15 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
      {{ $t("fraud_protect.s3_desc2") }}
    </div>
    <v-row class="evo-row evo-items-wrapper" justify="space-between">
      <v-col v-for="(item, index) in items" :key="index">
        <div class="evo-item" data-aos="fade-up" data-aos-delay="150">
          <img loading="lazy"  :src="item.icon" class="evo-img" />
          <img loading="lazy" 
            v-if="item.iconR"
            :src="item.iconR"
            :class="{
              'evo-img-top': index % 2 === 0,
              'evo-img-bottom': index % 2 === 1,
            }"
          />
          <div class="evo-desc evo-text-13 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { fraudProtectSection3Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: "EvoFraudProtectSectionThree",
  mixins: [fraudProtectSection3Mixin]
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;
  background: #fafafa;

  & > .evo-title,
  & > .evo-desc {
    text-align: center;
  }

  & > .evo-title {
    color: #222a41;
    margin-bottom: 16px;
  }

  & > .evo-desc {
    color: #6d7783;
    margin-bottom: 4px;
  }

  .evo-items-wrapper {
    margin-top: 48px;
    .evo-item {
      position: relative;
      text-align: center;

      .evo-img {
        width: 42px;
        height: 42px;
        margin-bottom: 4px;
      }

      .evo-img-top {
        position: absolute;
        top: 0px;
        right: -42px;
        width: 70px;
      }

      .evo-img-bottom {
        position: absolute;
        top: 16px;
        right: -42px;
        width: 70px;
      }

      .evo-desc {
        color: #151517;
      }
    }
  }
}
</style>
