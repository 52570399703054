<template>
  <div class="section-1 evo-f-center evo-pv-120">
    <div class="evo-mobile">
      <div class="evo-text-66 evo-title evo-mb-48">
        {{ $t('fraud_protect.title') }}
      </div>
      <div class="evo-text-33 evo-desc evo-mb-100 evo-opensans-regular">
        {{ $t('fraud_protect.desc') }}
      </div>
      <div class="evo-get-started">
        <router-link to="contact-us" class="router-link">
          <v-btn rounded x-large color="default" class="text-none evo-get-started-btn">
            <span class="evo-text-29">{{ $t('main_entry.get_started') }}</span>
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EvoMFraudProtectSectionOne'
};
</script>
<style lang="scss" scoped>
.section-1 {
  position: relative;
  background-image: url('~@/assets/fraud-protect/s1-bg-mobile.svg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  color: #ffffff;
  height: 160vw;

  .evo-title,
  .evo-desc {
    text-align: center;
  }

  .evo-get-started {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .evo-get-started-btn {
      @include whiteblue_btn;
      min-width: 280px;
      color: #459eff;
    }
  }
}
</style>
