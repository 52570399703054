<template>
  <div class="section-4 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-text-58 text-center evo-title evo-mb-8">
        {{ $t('fraud_protect.s4_title') }}
      </div>
      <div class="evo-text-30 text-center evo-opensans-regular evo-sub-title evo-mb-48">
        {{ $t('fraud_protect.s4_sub_title') }}
      </div>

      <v-row align="center" justify="space-between">
        <v-col cols="12" v-for="(item, index) in solutions" :key="index">
          <div class="evo-solutions" :class="{ 'is-white-bg': index % 2 !== 1 }">
            <img class="evo-item-img" loading="lazy" :src="item.icon" />
            <div class="evo-item-title evo-text-50">{{ $t(item.title) }}</div>
            <div class="evo-item-desc evo-text-30 evo-opensans-regular">
              {{ $t(item.desc) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <EvoEllipse class="evo-s4-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="200px" height="200px" />
    </div>
  </div>
</template>
<script>
import { fraudProtectSection4Mixin } from '@/mixins/fraud-protect.js';
export default {
  name: 'EvoMFraudProtectSectionFour',
  mixins: [fraudProtectSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: $header_bg_color;

  .evo-title {
    color: #222a41;
    position: relative;
    z-index: 1;
  }

  .evo-sub-title {
    color: #a0a3a8;
    position: relative;
    z-index: 1;
  }

  .evo-solutions {
    background: #2161ff;
    box-shadow: 0px 11px 11px 0px rgba(0, 0, 0, 0.03);
    border-radius: 32px;
    padding: 48px;
    min-height: 264px;
    position: relative;
    width: 640px;
    margin: 0 auto;
    z-index: 1;

    &.is-white-bg {
      background: #fff;

      .evo-item-title {
        color: $title_color !important;
      }

      .evo-item-desc {
        color: #a0a3a8 !important;
      }
    }
    .evo-item-img {
      width: 172px;
      height: auto;
      margin-bottom: 16px;
    }

    .evo-item-title {
      color: #ffffff;
      margin-bottom: 24px;
    }

    .evo-item-desc {
      color: #ffffff;
    }
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 124px;
    top: 124px;
    z-index: 0;
  }
}
</style>
